import { Menu as MenuIcon } from '@mui/icons-material';
import { BottomNavigation, createTheme, Fab, Grid, List, ThemeProvider, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import subscriptionApi from 'api/subscription';
import CustomImageAvatar from "components/Avatar/CustomImageAvatar";
import CustomListItem from "components/Drawer/CustomListItem";
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
import CustomFooter from 'components/Footer/CustomFooter';
import TopHeader from "components/header/TopHeader";
import AuthRoutes from "components/Routes/AuthRoutes";
import { getMenuItems } from "constants/menuItems";
import { AuthConsumer } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import authService from 'services/auth.service';
import { fabStyleToggleMenuButton } from 'themes/defaultThemes';
import { hasAnyOfRoles, hasRoleAdmin, hasRoleDirector } from 'utils/auth';
import { isMobileDevice } from 'utils/getScreen';
import useDocumentBodyClass from 'utils/useDocumentBodyClass';

/**
 * The Home component after user has Logged in successfully.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AuthenticatedHomeComponent({ user }) {
    const SIDE_NAV_WIDTH = 270;
    // const TOP_NAV_HEIGHT = 45;

    const isMobile = useMediaQuery('(max-width:468px)');
    const { t } = useTranslation();
    useDocumentBodyClass("no-touch usee");
    const menuItems = getMenuItems();

    // DRAWER
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            display: "flow-root !important",
                            position: "relative"
                        }
                    }
                },
                MuiDrawer: {
                    styleOverrides: {
                        paper: {
                            display: "flow-root !important",
                            position: "relative"
                        }
                    }
                }
            }
        })
    }



    const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const [notify, setNotify] = useState(false);
    const navigate = useNavigate(); // If you're using React Router


    useEffect(() => {
        // Function to check if the subscription is expired and update the state
        const checkSubscriptionStatus = () => {
            subscriptionApi.fetchRemainingDays().then((r) => {
                setSubscriptionDaysLeft(r.data.returnobject.remainingDays)
                setNotify(r.data.returnobject.notify)
                // setNotify(true)
            }).catch((e) => {
                // console.log(e);
            })
        };

        // Initial check when the component mounts
        checkSubscriptionStatus();

        // Set an interval to check every 24 hours (86,400,000 ms)
        const intervalId = setInterval(() => {
            // Decrease daysRemaining by 1 to simulate the passage of time.
            // You would update this logic to fetch the real remaining days from your backend.
            setSubscriptionDaysLeft((prev) => prev - 1);
            checkSubscriptionStatus();
        }, 86400000); // 24 hours in milliseconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

        //     // Create a new WebSocket connection
        //     const socket = new WebSocket('ws://localhost:8080/websocket/subscription-status'); // Replace with your WebSocket URL

        //     // When the WebSocket connection opens
        //     socket.onopen = () => {
        //         // Send the JWT token to the server as the first message
        //         socket.send(localStorage.getItem("token"));
        //     };

        //     // When a message is received from the WebSocket server
        //     socket.onmessage = (event) => {
        //         const data = event.data;
        //         try {
        //             const parsedData = JSON.parse(data);
        //             // Assuming the server sends back the number of days left
        //             setSubscriptionDaysLeft(parsedData.daysLeft);
        //         } catch (error) {
        //             console.error('Error parsing WebSocket message:', error);
        //         }
        //     };

        //     // Handle WebSocket errors
        //     socket.onerror = (error) => {
        //         // console.error('WebSocket Error:', error);
        //     };

        //     // Handle WebSocket closure
        //     socket.onclose = () => {
        //         // console.log('WebSocket connection closed');
        //     };

        //     // Cleanup the WebSocket connection when the component unmounts
        //     return () => {
        //         socket.close();
        //     };
    }, []);

    useEffect(() => {
        // Logic to determine the remaining days (you can calculate based on a real expiration date)
        if (subscriptionDaysLeft <= 0) {
            setIsExpired(true);
            const currentURL = window.location.href;
            if (hasRoleDirector(user.roles)) {
                const renewalPageURL = '/subscription'; // Your renewal page URL
                // If the current page is not the renewal page, redirect automatically
                if (!currentURL.includes(renewalPageURL)) {
                    // navigate(renewalPageURL); // If using React Router
                    // window.location.href = renewalPageURL; // Alternatively, use this for non-Router apps
                }
            } else if (!hasRoleAdmin(user.roles)) {
                alert("The subscription has expired!")
                authService.logout();
            }
        }
    }, [subscriptionDaysLeft]);

    // const handleRenewClick = () => {
    //     // Logic for handling subscription renewal (redirect, open modal, etc.)
    //     window.location.href = '/subscription'; // Redirect to renewal page
    // };


    // ==================== RENDER COMPONENT

    return (
        <React.Fragment>
            <AuthConsumer>
                {({ user, sidenav, handleSidenavStateChange }) => (
                    <>
                        {!isMobile &&
                            <TopHeader key="th" user={user} notify={notify} isExpired={isExpired} />
                        }
                        {isMobile && !isMobileDevice() &&
                            <Fab color="primary"
                                aria-label="add"
                                sx={fabStyleToggleMenuButton()}
                                onClick={() => handleSidenavStateChange(!sidenav.expanded)}
                            >
                                <MenuIcon />
                            </Fab>
                        }

                        <div id="content-wrapper" className="column" data-select2-id="content-wrapper" key="cw">
                            <ThemeProvider theme={getMuiTheme()}>

                                {!isMobile && !(notify && isExpired) &&
                                    <Drawer
                                        id={`layout-sidebar${(sidenav.expanded) ? "-expanded" : ""}`}
                                        variant="permanent"
                                        open={sidenav.expanded}
                                        PaperProps={{
                                            sx: {
                                                backgroundColor: 'background.default',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: `100%`,
                                                p: 1,
                                                top: 0,
                                                padding: 0,
                                                width: SIDE_NAV_WIDTH,
                                                zIndex: (theme) => theme.zIndex.appBar - 100
                                            }
                                        }}
                                    >
                                        <Grid container gap={0} sx={{ height: '100%' }}>
                                            {/* user avatar */}
                                            <CustomImageAvatar name={user.username} sx={{ display: (!sidenav.expanded) ? "none" : "-webkit-box", marginTop: "10px" }} />

                                            {/* menu items */}
                                            <Grid item id="menu-items"
                                                xs={12}
                                                sx={{
                                                    height: 'calc(100% - 130px)',
                                                }}>
                                                <List>
                                                    {menuItems.map((menuItem) =>
                                                        hasAnyOfRoles(user.roles, menuItem.roles) &&
                                                        <CustomListItem key={menuItem.label} sidenav={sidenav} label={t(`menu.${menuItem.label}`)} icon={menuItem.icon} link={menuItem.link} roles={user.roles} children={menuItem.children} />
                                                    )}
                                                </List>
                                            </Grid>

                                            {sidenav.expanded &&
                                                <Grid item xs={12}>
                                                    <BottomNavigation
                                                        showLabels
                                                    >
                                                        <CustomFooter />
                                                    </BottomNavigation>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Drawer>
                                }
                            </ThemeProvider>

                            <div id={`main-wrapper${(sidenav.expanded) ? "-expanded" : ""}`} className="tr">
                                <AuthRoutes />
                            </div>
                        </div>

                        {isMobile && !isMobileDevice() && !(notify && isExpired) &&
                            <CustomSwippableDrawer
                                open={isMobile ? sidenav.expanded : false}
                                onClose={handleDrawerClose}
                                onOpen={handleDrawerOpen}
                                anchor={"left"}
                                sx={{ width: SIDE_NAV_WIDTH }}
                            >
                                <Drawer
                                    id={`layout-sidebar${(sidenav.expanded) ? "-expanded" : ""}`}
                                    variant="permanent"
                                    open={sidenav.expanded}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: 'white',
                                            p: 1,
                                            width: SIDE_NAV_WIDTH,
                                            // height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
                                            zIndex: (theme) => theme.zIndex.appBar - 100
                                        }
                                    }}
                                >
                                    <Grid container gap={1} sx={{ height: '100%' }}>
                                        {/* user avatar */}
                                        <CustomImageAvatar name={user.username} sx={{ display: (!sidenav.expanded) ? "none" : "-webkit-box", marginTop: "10px" }} />

                                        {/* menu items */}
                                        <Grid item xs={12}
                                            id="menu-items"
                                            sx={{
                                                height: 'calc(100% - 140px)',
                                            }}>
                                            <List>
                                                {menuItems.map((menuItem) =>
                                                    hasAnyOfRoles(user.roles, menuItem.roles) &&
                                                    <CustomListItem key={menuItem.label} sidenav={sidenav} label={t(`menu.${menuItem.label}`)} icon={menuItem.icon} link={menuItem.link} roles={user.roles} children={menuItem.children} />
                                                )}
                                            </List>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <BottomNavigation
                                                showLabels
                                            >
                                                <CustomFooter />
                                            </BottomNavigation>
                                        </Grid>
                                    </Grid>
                                </Drawer>
                            </CustomSwippableDrawer>
                        }
                    </>
                )}
            </AuthConsumer>
        </React.Fragment>
    );
}

export default AuthenticatedHomeComponent;