import { Button, Tooltip } from '@mui/material';

/**
 * The Custom Button, that display an button.
 * 
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomIconButton({ classes, keyValue, color, variant = "text", label, icon, onClick, link, sx, iconSx, tooltipTitle, backgroundColor = "#2596be", isFocused = false, disabled = false }) {

    return (
        <>
            <Tooltip arrow title={tooltipTitle} sx={{ zIndex: 1000000 }}>
                <Button
                    classes={classes}
                    variant={variant}
                    key={keyValue}
                    startIcon={icon}
                    href={link}
                    onClick={onClick}
                    disabled={disabled}
                    color={color}
                    sx={{
                        backgroundColor: color ? "inherit" : isFocused ? backgroundColor : "#fff",
                        color: color ? "undefined" : isFocused ? "#fff" : backgroundColor,
                        margin: "0px",
                        fontSize: "8px",
                        fontWeight: "900",
                        ...sx,
                        "&:hover": {
                            backgroundColor: color ? "inherit" : backgroundColor,
                            color: color ? "undefined" : "white",
                        },
                        '.MuiButton-startIcon': {
                            ...iconSx,
                            marginLeft: "0px"
                        }
                    }}
                >
                    {label}
                </Button>
            </Tooltip>
        </>
    );
}

export default CustomIconButton;