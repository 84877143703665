import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Divider, Fab, Grid, InputLabel } from '@mui/material';
import { default as subscriptionApi } from "api/subscription";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fabStyleCart, mainArea, mainTag, subscriptionDetailsHeaderStyle, subscriptionDetailsLabelStyle, subscriptionDetailsTextStyle, subscriptionHistoryCardStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle } from 'themes/defaultThemes';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
// assets
import vivaPaymentApi from 'api/vivaPayment';
import CustomChip from 'components/Chip/CustomChip';
import CustomSubscriptionTimeline from 'components/Timeline/CustomSubscriptionTimeline';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDateTimeFormatter } from 'constants/calendar';
import { dateTimeFormatter, days_between, findCurrency, isValidEmail } from 'utils/functions';
import WebFont from 'webfontloader';
import SubscriptionPendingChargesInstanceOutletCharge from 'components/Grid/subscription/SubscriptionPendingChargesInstanceOutletCharge';
import CustomIconButton from 'components/Button/CustomIconButton';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CustomChipEmailInput from 'components/Chip/CustomChipEmailInput';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The SubscriptionOrganizationComponent that triggers the creation of a 
 * new subscription or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionOrganizationComponent() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    /**
     * @type {object}
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated user infromation
     * @property {object} subscription an empty object if add or the selected subscription to be editted
     * @property {array} statusOptions the different status options
     * @property {array} subscriptionPlans the subscription options to select from
     * @property {array} organizations the organization options to select from
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        subscription: null,
        statusOptions: [],
        subscriptionPlans: [],
        organizations: [],
        currency: "€",
        // error messages per field
        isError: {
            organizationID: "",
            planID: "",
            status: ""
        }
    });

    const [renewSubscription, setRenewSubscription] = useState({
        organizationID: null,
        buyerID: null,
        amount: 0
    });

    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    //change document title
    useDocumentTitle(`Usee | ${t('subscription.pageTitle')}`);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });
        getSubscription();
    }, []);

    /**
     * The rest endpoint to get the subscription default (add) or current (edit) information.
     */
    function getSubscription() {
        subscriptionApi.fetchOneDirector(data.subscriptionID).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject?.userAuth,
                        outletBranches: r.data.returnobject?.outletBranches,
                        subscription: r.data.returnobject?.subscription,
                        statusOptions: r.data.returnobject?.statusOptions,
                        subscriptionPlans: r.data.returnobject?.subscriptionPlans,
                        organizations: r.data.returnobject?.organizations,
                        currency: findCurrency(r.data.returnobject.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === r.data.returnobject?.subscription?.subscriptionPlan?.id).prices[0]?.currencyString)
                    });
                })
                .then(() => {
                    setRenewSubscription({
                        ...renewSubscription,
                        organization: "usee",
                        buyerID: r.data.returnobject?.subscription?.organization?.id,
                        amount: calculateTotalWithFee()
                    });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * The rest endpoint to cancel recurrent payment for organizations' subscription.
     */
    function cancelRecurrentSubscriptionPayment() {
        subscriptionApi.cancelRecurrentSubscriptionPayment(data.subscription.organizationID).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e);
        })
    }


    function paySubscription() {
        let subDetails = {
            organizationID: data.subscription?.organization?.id,
            buyerID: data.userAuth?.id,
            amount: 1,//calculateTotalWithFee(),
            isCustomer: false,
            subscription: {
                subscriptionID: data.subscription?.id,
                details: {
                    organizationCharges: data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id).prices[0].price,
                    totalAmount: calculateTotalWithFee(),
                    outletCharges: calculateOutletCharges(),
                    currency: data.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id).prices[0]?.currencyString
                }
            },
            currency: data?.currency,
            paymentType: "S"
        }
        vivaPaymentApi.getPaymentUrlReccurent(subDetails).then((r) => {
            if (r.data.code !== "SUCCESS") {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }
            if (r.data.code === "SUCCESS") {
                window.location.href = `${r.data.returnobject.redirectURL}&path=subscription}`;
            }
        }).catch((e) => {
            // console.log(e);
        });
    }


    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Calculates the total amount based on the service usage counters and the service charges.
     *
     * This function iterates over the service counters in the provided data object and
     * multiplies each counter with its corresponding service charge defined in the
     * subscription plan. The total amount is the sum of these individual amounts.
     *
     * @param {Object} data - Object containing service usage counters.
     * @param {Object} subscriptionPlan - Object containing service charges.
     * @param {number} data.smsCounter - Number of SMS sent.
     * @param {number} data.emailCounter - Number of emails sent.
     * @param {number} data.ticketCounter - Number of tickets used.
     * @param {number} data.receiptTicketCounter - Number of receipt tickets used.
     * @param {Object[]} subscriptionPlan.services - Array of service objects with key-value pairs.
     * @returns {number} The calculated total amount based on the service usage and charges.
     */
    function calculateAmount(data, subscriptionPlan) {
        let amount = 0;

        // Mapping service keys to their charges
        const serviceCharges = subscriptionPlan?.services?.reduce((charges, service) => {
            charges[service.key] = parseFloat(service.value);
            return charges;
        }, {});

        // Calculating the amount
        if (data.smsCounter) amount += data.smsCounter * (serviceCharges.SMS || 0);
        if (data.emailCounter) amount += data.emailCounter * (serviceCharges.EMAIL || 0);
        if (data.ticketCounter) amount += data.ticketCounter * (serviceCharges.PRINTER_TICKET || 0);
        if (data.receiptTicketCounter) amount += data.receiptTicketCounter * (serviceCharges.RECEIPT_TICKET || 0);

        return amount;
    }

    /**
     * Calculates the total amount for a list of data objects, adds a double of a fixed fee to the total.
     *
     * This function iterates over each data object in the provided list, calculates the amount
     * for each using the calculateAmount function, sums these amounts, and then adds double
     * the value of a provided fixed fee to this sum.
     *
     * @returns {number} The total amount including the double of the fixed fee.
     */
    function calculateTotalWithFee() {
        //List of data objects containing service usage counters.
        let dataList = data?.outletBranches;
        //Object containing service charges.
        let subscriptionPlan = data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id);
        // The fixed organization fee value to be doubled and added to the total amount.
        let fee = isRenewalDueSoon(data.subscription) ? data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id).prices[0].price : 0;
        let totalAmount = 0;

        dataList?.forEach(data => {
            totalAmount += calculateAmount(data, subscriptionPlan);
        });
        return totalAmount + fee;
    }

    /**
     * Calculates the total charges for each outlet based on service usage.
     *
     * This function processes a list of data objects, each representing an outlet's service usage,
     * and calculates the total charges for each outlet. The charges are calculated using the
     * calculateAmount function. The function returns an array of objects, each containing the
     * outlet ID and the calculated total amount for that outlet.
     *
     * @returns {Object[]} An array of objects with the total charges calculated for each outlet.
     */
    function calculateOutletCharges() {
        //List of data objects containing service usage counters.
        let dataList = data?.outletBranches;
        //Object containing service charges.
        let subscriptionPlan = data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id);
        let outletCharges = [];

        dataList?.forEach(instance => {
            let amount = calculateAmount(instance, subscriptionPlan);

            // Mapping service keys to their charges
            const serviceCharges = subscriptionPlan?.services?.reduce((charges, service) => {
                charges[service.key] = parseFloat(service.value);
                return charges;
            }, {});
            outletCharges.push({
                outletID: instance.outlet?.id,
                smsCounter: instance.smsCounter,
                smsPrice: serviceCharges.SMS,
                emailCounter: instance.emailCounter,
                emailPrice: serviceCharges.EMAIL,
                ticketCounter: instance.ticketCounter,
                ticketPrice: serviceCharges.PRINTER_TICKET,
                receiptTicketCounter: instance.receiptTicketCounter,
                receiptTicketPrice: serviceCharges.RECEIPT_TICKET,
                amount: amount
            });
        });

        return outletCharges;
    }

    /**
     * Checks if the renewal of a subscription is due within 11 days.
     *
     * This function first searches for the last "RENEWED" status in the actionsHistory.
     * If not found, it searches for the last "ACTIVE" status. It then calculates if the 
     * renewal of the subscription plan is due in less than 11 days based on the
     * subscription plan's type.
     *
     * @param {Object} subscription - The subscription object containing actionsHistory and subscriptionPlan.
     * @returns {boolean} Returns true if the renewal is due within 11 days, otherwise false.
     */
    function isRenewalDueSoon(subscription) {
        const actionsHistory = subscription?.actionsHistory;
        const subscriptionPlan = subscription?.subscriptionPlan;
        if (actionsHistory) {
            let lastAction = [...actionsHistory]?.reverse().find(action => action.status === 'RENEWED');
            if (!lastAction) {
                // If no RENEWED status is found, look for the last ACTIVE status
                lastAction = [...actionsHistory]?.reverse().find(action => action.status === 'ACTIVE');
            }

            // If neither RENEWED nor ACTIVE status is found, return false
            if (!lastAction) return false;

            const lastActionDate = new Date(lastAction.dateTime);
            let nextRenewalDate = new Date(lastActionDate);

            switch (subscriptionPlan?.type) {
                case 'MONTHLY':
                    nextRenewalDate.setMonth(lastActionDate.getMonth() + 1);
                    break;
                case 'YEARLY':
                    nextRenewalDate.setFullYear(lastActionDate.getFullYear() + 1);
                    break;
                case 'HALF_YEAR':
                    nextRenewalDate.setMonth(lastActionDate.getMonth() + 6);
                    break;
                case 'TWO_YEARS':
                    nextRenewalDate.setFullYear(lastActionDate.getFullYear() + 2);
                    break;
                case 'QUARTER':
                    nextRenewalDate.setMonth(lastActionDate.getMonth() + 3);
                    break;
                default:
                    return false; // Unrecognized subscription type
            }

            const currentDate = new Date();
            const timeDiff = nextRenewalDate - currentDate;
            const daysRemaining = timeDiff / (1000 * 60 * 60 * 24);

            return daysRemaining < 11;
        } else {
            return false;
        }
    }


   // ===================================== EMAILS

   function handleEmailsFieldChange(value) {
    let newEmails = value.map(email => ({ email, isEnabled: true }))

    formValChangeWithParentElementWithNameAndValue("subscription.emails", newEmails, data, setData);
 }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(`/${getLanguageFromURL()}/subscription`)
    } else if (!isLoaded) {
        return <CustomGrid role="main" id={"subscription"} sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {

        return (
            <CustomGrid role="main" id={"subscription"} sx={mainTag()}>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="subscription" onSubmit={(e) => { return false; }}>

                        <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTypography variant="body2" text={t('subscription.organization.label')} sx={subscriptionDetailsHeaderStyle()} />
                                <Grid container spacing={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                    <Grid item xs={6} md={5}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.currentPlan')} sx={subscriptionDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        <CustomTypography variant="body2" text={data?.subscription?.subscriptionPlan?.name} sx={subscriptionDetailsTextStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.payPeriod')} sx={subscriptionDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        <CustomTypography variant="body2" text={`${t('subscriptionPlan.type.' + data?.subscription?.subscriptionPlan?.type)}`} sx={subscriptionDetailsTextStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.memberSince')} sx={subscriptionDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        <CustomTypography variant="body2" text={dateTimeFormatter(data?.subscription?.dateCreated, getDateTimeFormatter())} sx={subscriptionDetailsTextStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.dueDate')} sx={subscriptionDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        <CustomTypography variant="body2" text={dateTimeFormatter(data?.subscription?.dueDate, getDateTimeFormatter())} sx={subscriptionDetailsTextStyle()} />
                                        <CustomChip label={`${days_between(data?.subscription?.dueDate)}  ${t('label.days')}`} sx={{ fontSize: "8px" }} />
                                        <CustomIconButton
                                            onClick={() => cancelRecurrentSubscriptionPayment()}
                                            tooltipTitle={t('subscription.cancelReccurentPaymentBtnTooltip')}
                                            icon={<EventRepeatIcon sx={{ color: "red" }} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <InputLabel required={true} htmlFor="recurrentStatus">{t('subscription.emails')}</InputLabel>
                                        <CustomChipEmailInput
                                            id="emails"
                                            name="subscription.emails"
                                            // label={t("subscription.emails")}
                                            allowDuplicates={false}
                                            value={data.subscription.emails}
                                            validateChip={isValidEmail}
                                            onChange={(event) => handleEmailsFieldChange(event)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={10}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.pendingCharges')} sx={{ ...subscriptionDetailsLabelStyle(), marginBottom: "5px" }} />
                                        {data?.outletBranches?.map((outletBranch) =>
                                            <SubscriptionPendingChargesInstanceOutletCharge currency={data.currency} outletBranch={outletBranch} subscriptionPlan={data.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id)} />
                                        )}
                                        <Grid container sx={{ marginLeft: "20px" }}>
                                            {isRenewalDueSoon(data?.subscription) &&
                                                <>
                                                    <Grid item xs={7} md={7}>
                                                        <CustomTypography variant="body2" text={t('subscription.organization.history.organizationCharges')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                                    </Grid>
                                                    <Grid item xs={5} md={5}>
                                                        <CustomTypography variant="body2" text={data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id).prices[0].price} sx={subscriptionHistoryDetailsTextStyle()} />
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12} md={12}>
                                                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginBottom: "5px", marginTop: "15px" }} />
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <CustomTypography variant="body2" text={t('subscription.organization.history.totalAmount')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                            </Grid>
                                            <Grid item xs={5} md={5}>
                                                <CustomTypography variant="body2" text={`${calculateTotalWithFee()} ${data.currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomTypography variant="body2" text={t('subscription.history')} sx={subscriptionDetailsHeaderStyle()} />
                                <CustomSubscriptionTimeline timelineDetails={data?.subscription?.actionsHistory} subscriptionPlans={data?.subscriptionPlans} outletBranches={data.outletBranches} />
                            </Grid>
                        </Grid>

                        {/* <CustomIconButton variant="outlined" keyValue="pay" onClick={renewSubscriptionCallback} label={`${t('actions.pay')} ${renewSubscription.amount > 0 ? `(${renewSubscription.amount}  ${data.currency})` : ""}`} sx={formSaveButton()} /> */}

                        {calculateTotalWithFee() > 0 &&
                            <Fab
                                color="primary"
                                aria-label="pay"
                                sx={fabStyleCart()}
                                onClick={() => paySubscription()}
                            >
                                <ShoppingCartIcon sx={{ marginRight: "10px" }} />
                                {` ${calculateTotalWithFee()} ${data?.currency}`}
                            </Fab>
                        }
                    </form>
                </CustomGrid>


                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />

                }
            </CustomGrid>
        );
    }
}

export default SubscriptionOrganizationComponent;