import ClearIcon from '@mui/icons-material/Clear';
import DehazeIcon from '@mui/icons-material/Dehaze';
import HeightIcon from '@mui/icons-material/Height';
import { createTheme, Divider, Grid, ListItem, ListItemIcon, ListItemText, ThemeProvider, Tooltip, useMediaQuery } from "@mui/material";
import { CheckIcon, EditIcon, DeleteIcon } from 'assets/mui/MuiIcons';
import CustomTextField from 'components/Text/CustomTextField';
import { useEffect, useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { openInNewTab } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";

/**
 * A Custom Draggable List Item.
 * 
 * @param {object} item a specific modifier-list object
 * @param {int} index modifier-list item index
 * @param {*} remove function that removes the item/modifier instance
 * @param {string} organizationID The id of the organization
 * @param {boolean} enableDraggable Has value true when draggable icon is visible.
 *
 * @returns {JSX.Element} A React component that renders a custom draggable list item for modifiers/items.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomIngredientDraggableTableItem({ ingredient, index, remove, enableDraggable, lang = getLanguageFromURL(), save }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:468px)');
    const [show, setShow] = useState(false);
    const [windowdWidth, setWindowWidth] = useState("")

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setWindowWidth(document.querySelector("ul.droppable-ingredients-list").clientWidth);
        }

        window.addEventListener('resize', handleResize)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log(ingredient)
    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={ingredient.id} index={index} key={ingredient.id}>
                {(provided, snapshot) => (
                    <>
                        {index !== 0 &&
                            <Divider style={{ width: "80%", marginLeft: "70px" }} />
                        }
                        <ListItem
                            sx={{
                                background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                width: "100%",
                                borderBottom: "1px",
                                userSelect: "none",
                                padding: "20px 10px",//: "20px",
                                borderRadius: "4px",
                                ...provided.draggableProps.style,
                                height: "30px"
                            }}
                            {...provided.draggableProps} ref={provided.innerRef}
                        >
                            <Grid container spacing={0} alignItems="center" sx={{ marginLeft: "-18px" }}>
                                <Grid item xs={3} sm={2} md={1}>
                                    <ListItemIcon sx={{ visibility: (enableDraggable) ? "visible" : "hidden" }}  {...provided.dragHandleProps}>
                                        <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                        <DehazeIcon style={{ marginRight: 15, marginLeft: "-6px", fontSize: "20px", width: "15px", color: "lightgrey", paddingTop: "1px" }} />
                                    </ListItemIcon>
                                </Grid>

                                <Grid item xs={4} sm={6}>
                                    <ListItemText primary={ingredient.name[lang] ? ingredient.name[lang] : ingredient.name.en} />
                                </Grid>

                                {!show &&
                                    <>
                                        <Grid item xs="auto">
                                            <ListItemText primary={ingredient.quantityToBeUsed} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={() => { setShow(true) }} /></Tooltip>
                                        </Grid>
                                    </>
                                }

                                {show &&
                                    <>
                                        <Grid item xs={2}>
                                            <CustomTextField
                                                type="number"
                                                step="0.1"
                                                min={0.0}
                                                name="quantityToBeUsed"
                                                id={`${ingredient.id}-quantityToBeUsed`}
                                                defaultValue={ingredient.quantityToBeUsed !== null && ingredient.quantityToBeUsed !== "" && ingredient.quantityToBeUsed !== undefined ? ingredient.quantityToBeUsed : 1}

                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <div style={{ display: "flex", marginTop: "-15px", marginLeft: "-5px" }}>
                                                <Tooltip title={t("actions.save")}><CheckIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }} onClick={(event) => { setShow(false); save(event, ingredient.id, document.getElementById(`${ingredient.id}-quantityToBeUsed`).value) }} /></Tooltip>
                                                <Tooltip title={t("actions.cancel")}><ClearIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }} onClick={() => setShow(false)} /></Tooltip>
                                            </div>
                                        </Grid>
                                    </>
                                }

                                {((!isMobile) || isMobile && !show) &&
                                    <Grid item xs={2} sm={2} sx={{ textAlign: 'right' }}>
                                        <Grid container spacing={1} alignItems="right">
                                            <Grid item xs={6}>
                                                <Tooltip arrow title={t("actions.remove")}><DeleteIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "30px", marginRight: "20px" }} onClick={((event) => remove(event, ingredient))} /></Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </ListItem>
                    </>
                )}
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomIngredientDraggableTableItem;