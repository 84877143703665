import CustomGrid from 'components/Grid/CustomGrid';
import { mainTag } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";

import 'assets/scss/campaign.scss'; // Import custom style for editor
import EmailEditor from 'react-email-editor';
import { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import sample from './sample.json';
// Import the JSON translations
import enTranslations from 'constants/languages/emailEditor/en.json';
import elTranslations from 'constants/languages/emailEditor/el.json';
import { getLanguageFromURL } from 'utils/language';
import CustomChipEmailInput from 'components/Chip/CustomChipEmailInput';
import CustomTextField from 'components/Text/CustomTextField';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from 'utils/functions';

/**
 * The CampaignComponent, that presents the campaign editor main component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CampaignComponent() {
    const { t } = useTranslation();

    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const [locale, setLocale] = useState(getLanguageFromURL()); // Default locale
    const [translations, setTranslations] = useState(enTranslations); // Default translations


    // Set document title
    useDocumentTitle('Usee | Test');

    // Load the appropriate translations based on the locale
    useEffect(() => {
        if (locale === 'en') {
            setTranslations(enTranslations);
        } else if (locale === 'el') {
            setTranslations(elTranslations);
        }
    }, [locale]);

    // Save the design JSON
    const saveDesign = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.saveDesign((design) => {
            console.log('saveDesign', design);
            alert('Design JSON has been logged in your developer console.');
        });
    };

    // Export HTML from the design
    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', html);
            alert('Output HTML has been logged in your developer console.');
        });
    };

    const onDesignLoad = (data) => {
        console.log('onDesignLoad', data);
    };

    // Toggle preview mode
    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    // Hide branding on editor load
    const hideBranding = () => {
        const brandingElement = document.querySelector('a.blockbuilder-branding');
        if (brandingElement) {
            brandingElement.style.visibility = 'hidden';
        }
    };

    // Load a sample design when the editor is ready
    useEffect(() => {
        const unlayer = emailEditorRef.current?.editor;

        if (unlayer) {
            unlayer.addEventListener('design:loaded', (data) => {
                console.log('Design loaded:', data);
            });

            unlayer.loadDesign(sample);

            return () => {
                unlayer.removeEventListener('design:loaded', onDesignLoad);
            };
        }
    }, []);

    return (
        <CustomGrid role="main" id="test" sx={mainTag()}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={12}>
                    <CustomTextField
                        type="text"
                        name="subject"
                        InputProps={{
                            startAdornment: <span> {`${t('email.subject')}:`}</span>,
                        }}
                        // defaultValue={ticketConfigOfAction.subject}
                        // onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "subject", event.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomChipEmailInput
                        id="to"
                        name="ticketConfigOfAction.to"
                        label={t("email.to")}
                        allowDuplicates={false}
                        value={[{email: "gina.chatzimarkaki@gmail.com"}]}
                        // value={ticketConfigOfAction.to}
                        validateChip={isValidEmail}
                        // onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "to", event)}
                    />
                </Grid>
                <Grid item xs={12} sx={{}}>
                    <EmailEditor
                        ref={emailEditorRef}
                        onLoad={() => {
                            console.log('Editor loaded');
                            // hideBranding(); // Hide branding after the editor loads
                        }}
                        onReady={() => console.log('Editor ready')}
                        // locale='gr'
                        options={{
                            version: 'latest',
                            appearance: {
                                theme: 'modern_light',
                                // loader: {
                                //     url: 'https://example.com/loader.gif'  // URL of the custom loading spinner image
                                // },
                                panels: {
                                    tools: {
                                        dock: 'left',
                                        collapsible: true
                                    }
                                }
                            },
                            displayMode: "email",
                            // locale: locale,
                            translations: {
                                "en-US": translations
                            },
                            // customCSS: [
                            //     `
                            //        a.blockbuilder-branding: {
                            //             visibility: hidden
                            //         }
                            //     `
                            // ]
                        }}
                        style={{
                            minHeight: "700px",
                            // height: "90%"
                        }}
                    />
                </Grid>
                <Grid item xs={12}>

                    {/* <Button onClick={() => setLocale(locale === 'en' ? 'el' : 'en')}>
                        Switch to {locale === 'en' ? 'Greek' : 'English'}
                    </Button> */}
                    {/* <Button onClick={togglePreview}>
                        {preview ? 'Hide' : 'Show'} Preview
                    </Button> */}
                    <Button onClick={saveDesign}>Save Design</Button>
                    <Button onClick={exportHtml}>Export HTML</Button>
                </Grid>
            </Grid>

        </CustomGrid>
    );
}

export default CampaignComponent;