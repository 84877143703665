
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { mainAreaBodyDraggableTable } from 'themes/defaultThemes';
import CustomIngredientDraggableTableItem from "./CustomIngredientDraggableTableItem";
import { useMediaQuery } from "@mui/material";

/**
 * A Custom Draggable List.
 * @property {array} ingredients the array of the ingredients
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the `label` is empty
 * @property {*} removeIngredient function that removes the ingredient instance
 * @property {string} organizationID The id of the organization
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomIngredientDraggableTable({ ingredients, showEmptyMessage = true, emptyMessage, removeIngredient, enableDraggable = false, updateIngredientQuantity }) {
    const isMobile = useMediaQuery('(max-width:468px)');
    
    return (
        <>
            {(showEmptyMessage && (ingredients === null || ingredients?.length === 0)) &&
                <div className="body small" style={mainAreaBodyDraggableTable()}>
                    {emptyMessage}
                </div>
            }

            {(ingredients !== null && ingredients?.length > 0) &&
                <DragDropContext>
                    <Droppable droppableId={`droppable-ingredients-list`}>
                        {(provided, snapshot) => (
                            <ul className={`droppable-ingredients-list`} ref={provided.innerRef} {...provided.droppableProps} style={{
                                background: snapshot.isDraggingOver ? "lightblue" : '',
                                borderRadius: "4px",
                                width: isMobile? "98%" : "85%",
                                marginLeft: isMobile? "0px" : "85px"
                            }} >
                                {ingredients?.map((ingredient, index) => (
                                    <CustomIngredientDraggableTableItem
                                        ingredient={ingredient}
                                        index={index}
                                        key={ingredient.id}
                                        remove={removeIngredient}
                                        enableDraggable={enableDraggable}
                                        save={updateIngredientQuantity}
                                    />
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomIngredientDraggableTable;