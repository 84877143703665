import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import logo from 'assets/images/logo.png';
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomTypography from "components/Typography/CustomTypography";
import { AuthConsumer } from 'context/AuthContext';
import { useEffect, useState } from "react";
import { toggleMenuButton, toggleMenuIcon } from "themes/defaultThemes";
import MiniMenu from './mini-menu.component';
import { hasRoleDirector } from 'utils/auth';
import subscriptionApi from 'api/subscription';
import { useNavigate } from 'react-router-dom';

/**
 * The TopHeader, that display the Top bar with the search placehold, the 
 * account button and the notifications button.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function TopHeader({ user, notify = false, isExpired = false }) {
    //  redirect the information related to the redirect after successfully submit a query
    const [redirect, setRedirect] = useState({
        perform: false,
        url: ""
    })
    const navigate = useNavigate(); // If you're using React Router


    if (redirect.perform) {
        window.location.href = redirect.url;
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        return (
            <AuthConsumer>
                {({ sidenav, handleSidenavStateChange }) => (
                    <CustomGrid>
                        <Box sx={{ flexGrow: 1, background: "white" }}>
                            <AppBar position="fixed" style={{ background: "white", width: "100%" }} className={`column tr ${(sidenav.expanded) ? "expanded" : ""}`}>
                                <header id="header-wrapper" className={`column tr ${(sidenav.expanded) ? "expanded" : ""}`} role="banner">
                                    <Grid container gap={1} direction="row">
                                        {!(notify && isExpired) && 
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleSidenavStateChange(!sidenav.expanded)}
                                                    sx={toggleMenuButton()}
                                                >
                                                    <MenuIcon sx={toggleMenuIcon()} onClick={() => handleSidenavStateChange(!sidenav.expanded)} />
                                                </Button>
                                            </Grid>
                                        }

                                        <Grid item xs={"auto"}>
                                            <a href="/"><img src={logo} width="40" alt="" /></a>
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <CustomTypography
                                                variant="h5"
                                                text="Usee"
                                                sx={{ paddingTop: "5px" }}
                                            />
                                        </Grid>

                                        {notify &&
                                            <Box
                                                sx={{
                                                    width: '50%',
                                                    backgroundColor: '#000',
                                                    position: 'fixed',
                                                    top: 0,
                                                    left: 0,
                                                    // padding: 2,
                                                    zIndex: 1000,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    boxShadow: 2,
                                                    height: "44px"
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: "4px" }}>
                                                    {isExpired ? '⚠️ Your subscription has expired!' : '⏳ Your subscription is about to expire!'}
                                                </Typography>
                                                {/* <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                    {isExpired
                                                        ? 'Please renew to continue using our services.'
                                                        : `Only ${subscriptionDaysLeft} days left. Renew now to avoid interruption!`}
                                                </Typography> */}
                                                {/* <Button
                                                    variant="contained"
                                                    color="error"
                                                    sx={{ marginTop: 2 }}
                                                    onClick={handleRenewClick}
                                                >
                                                    Renew Now
                                                </Button> */}
                                            </Box>
                                        }
                                        <Box sx={{ flexGrow: 1 }} />

                                        <Grid item xs={"auto"} sx={{ alignSelf: "center" }}>
                                            <MiniMenu />
                                        </Grid>
                                    </Grid>
                                </header>
                            </AppBar>
                        </Box>
                    </CustomGrid>
                )}
            </AuthConsumer>
        );
    }
}

export default TopHeader;